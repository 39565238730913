import { lazy } from 'react';

import AdminRoute from '../components/AdminRoute';
import AdminGuestRoute from '../components/AdminGuestRoute';
import ForwardRoute from '../components/ForwardRoute';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import BarChartIcon from '@mui/icons-material/BarChart';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import KitchenIcon from '@mui/icons-material/Kitchen';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { IRoute } from '../types/routes';
import { parseDate } from '../util/date';
import { objectToSearchParams } from '../util/search_params';

const Login = lazy( () => import('../pages/login/Login') );
const Fridges = lazy( () => import('../pages/fridges/Fridges') );
const FridgeEdit = lazy( () => import('../pages/fridges/FridgeEdit') );
const Fillings = lazy( () => import('../pages/fillings/Fillings') );
const FridgesOnline = lazy( () => import('../pages/fridges/FridgesOnline') );
const PrintPage = lazy( () => import('../pages/print/PrintPage') );
const SlotsPage = lazy( () => import('../pages/vending/SlotsPage') );
const FridgesMenuEdit = lazy( () => import('../pages/fridgesMenu/FridgesMenuEdit') );

const ControlPanel = lazy( () => import('../pages/panel/ControlPanel') );

const Statistic = lazy( () => import('../pages/statistic/Statistic') );

const SalesPage = lazy( () => import('../pages/sales/SalesPage') );
const Users = lazy( () => import('../pages/users/Users') );
const UserEdit = lazy( () => import('../pages/users/UserEdit') );

const Customers = lazy( () => import('../pages/customers/Customers') );
const CustomerEdit = lazy( () => import('../pages/customers/CustomerEdit') );

const Discounts = lazy( () => import('../pages/marketing/discounts/Discounts') );
const DiscountEdit = lazy( () => import('../pages/marketing/discounts/DiscountEdit') );

const Promotions = lazy( () => import('../pages/marketing/promotions/Promotions') );

const Forward = lazy( () => import('../pages/forwarder/ForwardLayout') );

const Orders = lazy( () => import('../pages/orders/Orders') );
const OrderEdit = lazy( () => import('../pages/orders/OrderEdit') );

const SmartMetrics = lazy( () => import('../pages/smartmetrics/SmartMetrics') );

const Reviews = lazy( () => import('../pages/reviews/Reviews') );
const ReviewTags = lazy( () => import('../pages/reviewTags/ReviewTags') );

const Categories = lazy( () => import('../pages/menu/categories/Categories') );
const Products = lazy( () => import('../pages/menu/products/Products') );
const EditCategory = lazy( () => import('../pages/menu/categories/EditCategory') );
const EditProduct = lazy( () => import('../pages/menu/products/EditProduct') );

const SettingsHome = lazy( () => import('../pages/settings/Home/SettingsHome') );
const SettingsOrders = lazy( () => import('../pages/settings/Orders/Orders') );
const SettingsSmartmetrics = lazy( () => import('../pages/settings/Smartmetrics/Smartmetrics') );

const Companies = lazy( () => import('../pages/companies/Companies') );
const EditCompany = lazy( () => import('../pages/companies/EditCompany') );

const Support = lazy( () => import('../pages/support/Support') );
const SupportChat = lazy( () => import('../pages/support/supportChat/SupportChat') );

const Roles = lazy( () => import('../pages/roles/Roles') );
const CreateRole = lazy( () => import('../pages/roles/Crud/CreateRole') );
const EditRole = lazy( () => import('../pages/roles/Crud/EditRole') );

const PrivacyPolicy = lazy( () => import('../pages/pages/PrivacyPolicy') );
const PublicOffer = lazy( () => import('../pages/pages/PublicOffer') );

const ForwardSettings = lazy( () => import('../pages/forwardSalary/settings/ForwardSettings') );
const ForwardSalary = lazy( () => import('../pages/forwardSalary/ForwardSalary') );


export const getHomeRoute = () => {
  return routes[ 0 ] + '?' + objectToSearchParams( routes[ 0 ].params );
};

export const routes: IRoute[] = [
  {
    path: '/login',
    protection: AdminGuestRoute,
    component: Login
  },
  {
    icon: SettingsSuggestIcon,
    name: 'Dashboard',
    path: '/',
    protection: AdminRoute,
    component: ControlPanel,
    access: 'sales:list',
    params: {
      startOf: parseDate().format( 'YYYY-MM-DD' ),
      endOf: parseDate().format( 'YYYY-MM-DD' )
    }
  },
  {
    icon: BarChartIcon,
    name: 'Статистика',
    path: '/statistic',
    params: {
      startOf: parseDate().format( 'YYYY-MM-DD' ),
      endOf: parseDate().format( 'YYYY-MM-DD' )
    },
    protection: AdminRoute,
    component: Statistic,
    access: 'sales:list',
  },
  {
    name: 'Меню',
    icon: RestaurantMenuIcon,
    path: '/menu',
    protection: AdminRoute,
    access: [ 'categories:list', 'products:list' ],
    children: [
      {
        name: 'Категории',
        path: 'categories',
        component: Categories,
        access: 'categories:list',
      },
      {
        path: 'categories/:id',
        component: EditCategory,
      },
      {
        name: 'Товары',
        path: 'products',
        component: Products,
        access: 'products:list',
      },
      {
        path: 'products/:id',
        component: EditProduct,
      },
    ],
  },
  {
    name: 'Микромаркеты',
    icon: KitchenIcon,
    path: '',
    protection: AdminRoute,
    component: Fridges,
    access: [ 'fridges:list', 'fillings:list' ],
    children: [
      {
        name: 'Все',
        path: 'fridges',
        component: Fridges,
        access: 'fridges:list',
        params: {
          status: 1,
        }
      },
      {
        path: 'fridges/:id',
        component: FridgeEdit
      },
      {
        name: 'Онлайн',
        path: 'fridges/online',
        component: FridgesOnline,
        access: 'fridges:list',
        params: {
          status: 1
        },
      },
      {
        name: 'Заявки',
        path: 'fillings',
        component: Fillings,
        access: 'fillings:list',
        params: {
          fridgeStatus: 1
        },
      },
      {
        name: 'Печать',
        path: 'print',
        params: {
          fridgeStatus: 1,
          unlimited: true,
          status: 0,
        },
        component: PrintPage,
        access: 'fillings:list',
      },
      {
        name: 'Слоты вендинга',
        path: 'vending/slots',
        component: SlotsPage,
        access: 'fillings:update',
      },
      {
        name: 'Меню для микромаркетов',
        path: 'fridges/menu/market',
        component: FridgesMenuEdit
      },
      {
        name: 'Меню для вендинга',
        path: 'fridges/menu/vending',
        component: FridgesMenuEdit
      },
    ]
  },
  {
    icon: GroupIcon,
    name: 'Пользователи',
    path: '',
    access: [ 'users:list', 'customers:list', 'roles:list' ],
    protection: AdminRoute,
    children: [
      {
        name: 'Админы',
        path: 'users',
        component: Users,
        access: 'users:list',
        params: {
          typeId: 0
        }
      },
      {
        path: 'users/:id',
        component: UserEdit
      },
      {
        name: 'Покупатели',
        path: 'customers',
        component: Customers,
        access: 'customers:list',
      },
      {
        path: 'customers/:id',
        component: CustomerEdit
      },
      {
        name: 'Роли',
        path: 'roles',
        component: Roles,
        access: 'roles:list',
      },
      {
        path: 'roles/create',
        component: CreateRole,
      },
      {
        path: 'roles/:id',
        component: EditRole,
      },
    ]
  },
  {
    icon: DirectionsCarIcon,
    name: 'Экспедиторы',
    path: '',
    access: [ 'users:list' ],
    protection: AdminRoute,
    children: [
      {
        name: 'Экспедиторы',
        path: 'forwards',
        component: () => <Users forward={ true }/>,
        access: 'users:list',
        params: {
          typeId: 1,
        }
      },
      {
        path: 'forwards/:id',
        component: UserEdit
      },
      {
        name: 'Расчет заработка',
        path: 'forwardSalary',
        component: ForwardSalary,
        access: [ 'users:update' ],
      },
      {
        name: 'Расчет заработка  (настройка)',
        path: 'forwardSalary/settings',
        component: ForwardSettings,
        access: [ 'users:update' ],
      },
    ]
  },
  {
    icon: GroupIcon,
    name: 'Компании',
    path: '/companies',
    protection: AdminRoute,
    component: Companies,
    access: 'companies:list',
    children: [
      {
        path: ':id',
        component: EditCompany,
      },
    ]
  },
  {
    name: 'Заказы',
    icon: ShoppingCartIcon,
    path: '/orders',
    protection: AdminRoute,
    component: Orders,
    access: 'orders:list',
    children: [
      {
        path: ':id',
        component: OrderEdit,
      },
      {
        name: 'Отзывы',
        path: 'reviews',
        component: Reviews,
        access: 'reviews:list',
        params: {
          startOf: parseDate().format( 'YYYY-MM-DD' ),
          endOf: parseDate().format( 'YYYY-MM-DD' )
        }
      },
      {
        name: 'Теги отзывов',
        path: 'tags',
        component: ReviewTags,
        access: 'tags:list',
      },
      {
        name: 'Видеоаналитика (кражи)',
        path: 'smartmetrics',
        component: SmartMetrics,
        access: 'smartmetrics:list',
      }
    ]
  },
  {
    name: 'Маркетинг',
    icon: ShoppingCartIcon,
    path: '',
    protection: AdminRoute,
    access: [ 'discounts:list' ],
    children: [
      {
        name: 'Скидки',
        path: 'discounts',
        component: Discounts,
        access: 'discounts:list',
      },
      {
        path: 'discounts/:id',
        component: DiscountEdit
      },
      {
        name: 'Акции',
        path: 'promotions',
        component: Promotions,
        access: 'discounts:list',
      },
    ]
  },
  {
    icon: SettingsIcon,
    name: 'Настройки',
    path: '/settings',
    protection: AdminRoute,
    component: SettingsHome,
    access: 'settings:list',
    children: [
      {
        name: 'Видеоаналитика',
        path: 'smartmetrics',
        component: SettingsSmartmetrics
      },
      {
        name: 'Заказы',
        path: 'orders',
        component: SettingsOrders
      },
    ]
  },
  {
    icon: AttachMoneyIcon,
    name: 'Продажи',
    path: '/sales',
    protection: AdminRoute,
    component: SalesPage,
    access: 'sales:list',
  },
  {
    path: '/forward/*',
    protection: ForwardRoute,
    component: Forward,
  },
  {
    icon: HeadsetMicIcon,
    name: 'Поддержка',
    path: '/supports',
    protection: AdminRoute,
    component: Support,
    access: 'supports:list',
    children: [
      {
        path: ':id',
        component: SupportChat,
      }
    ]
  },
  {
    path: '/public-offer',
    component: PublicOffer,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  }
];
